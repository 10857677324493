/*
*************************************************************
*********************** Mid Estilos *************************
*************************************************************
*/
.generalTextoH2TituloFormulario {
  font-size: 36px;
  padding: 0 0 15px;
  font-weight: 700;
  margin: 10px 0;
  line-height: 1.1;
  color: #222d39;
}
.contenedorVideos {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1px 30px;
}
.place-autocomplete {
  position: absolute;
  z-index: 1;
  margin-top: -70px;
  width: 46%;
}
.iframe-video-home{
  width: 100%;
  height: 600px;
}
.background-color-dradiente-home{
  background: linear-gradient(180deg, #343D8E,#187581,#00A775);
  width: 100%;
  padding-bottom: 100px;
}

.background-color-dradiente {
  background: linear-gradient(180deg, #343D8E,#187581,#00A775);
  height: 50vh;
  padding-top: 50px;
}

@media screen and (max-width: 1125px) {

  .iframe-video-home{
    width: 100%;
    height: 580px;
  }
  
}

@media screen and (max-width: 1000px) {

  .iframe-video-home{
    width: 100%;
    height: 520px;
  }
}

@media screen and (max-width: 900px) {
  .iframe-video-home{
    width: 100%;
    height: 480px;
  }
  .contenedorVideos {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px 30px;
  }
}

@media screen and (max-width: 830px) {
  .iframe-video-home{
    width: 100%;
    height: 450px;
  }

}

@media screen and (max-width: 800px) {

  .iframe-video-home{
    width: 100%;
    height: 450px;
  }

}

@media screen and (max-width: 750px) {

  .contenedorVideos {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 30px 30px;
  }
}

@media screen and (max-width: 700px) {

  .iframe-video-home{
    width: 100%;
    height: 400px;
  }

}
@media screen and (max-width: 660px) {
  .generalTextoH2TituloFormulario {
      font-size: 26px;
  }
}
@media screen and (max-width: 600px) {

  .iframe-video-home{
    width: 100%;
    height: 350px;
  }

  .place-autocomplete {
    position: absolute;
    z-index: 1;
    margin-top: -70px;
    width: 92%;
  }

}

@media screen and (max-width: 525px) {

  .contenedorVideos {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 30px 30px;
  }
}

/**
 * Celular
*/

@media screen and (max-width: 420px) {
  .background-color-dradiente {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 50vh;
    padding-top: 30px;
  }

}

@media screen and (max-width: 400px) {
 
  .background-color-dradiente {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 50vh;
    padding-top: 30px;
  }
  .iframe-video-home{
    width: 100%;
    height: 250px;
  }

}

@media screen and (max-width: 382px) {
 
  .background-color-dradiente {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 50vh;
    padding-top: 30px;
  }
  .place-autocomplete {
    position: absolute;
    z-index: 1;
    margin-top: -70px;
    width: 90%;
  }

}

@media screen and (max-width: 360px) {
  .background-color-dradiente {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 50vh;
    padding-top: 30px;
  }
}

@media screen and (max-width: 340px) {

  .background-color-dradiente {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 50vh;
    padding-top: 30px;
  }

}