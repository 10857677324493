.cardTicketBotonTicket {
    box-sizing: border-box;
    text-decoration: none;
    letter-spacing: .5px;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.2rem 1rem;
    line-height: 187.5%;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color:#3c30920e;
    align-self: center !important;
    text-transform: uppercase !important;
    font-size: 0.875rem;
    cursor: pointer;
}
.cardTicketBotonTicket:hover {
    background-color:#3c30921a;
    color: #3340b8;
}