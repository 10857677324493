.cardCalificarEmogiColorGris {
    direction: ltr;
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    box-sizing: inherit;
    background: transparent;
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 2px 10px;
    cursor: pointer;
    text-align: center;
    -webkit-filter: grayscale(100%);
    outline: none;
    margin-right: 12px;
    transition: all ease 100ms;
    transform-origin: center center;
    will-change: transform,filter;
    pointer-events: all;
    opacity: 1;
}

.cardCalificarEmogiColorGris:hover {
    transform: scale(1.35);
    -webkit-filter: grayscale(0);
}
.cardCalificarEmogiColor {
    direction: ltr;
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    text-align: center;
    pointer-events: all;
    box-sizing: inherit;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 18px;
    margin-left: 6px;
    margin-bottom: 13px;
}
