.cardTecnicoImagenTecnico {
    height: 30vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cardTecnicoCard:hover  .cardTecnicoNombre{
    color: #3B3092;
}
.cardTecnicoContenedorImagenTecnico {
    margin-right: 15px;
    text-align: center;
}
/**
 * Media Screen
*/
@media screen and (max-width: 959px) {
    .cardTecnicoContenedorImagenTecnico {
        margin-bottom: 15px;
        margin-right: 0px;
    }
}