.modalNewDireccionContenedorBoton {
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 599px) {
    .modalNewDireccionMapa {
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
    .modalNewDireccionContenedorBoton {
        margin-top: -10px;
    }
}