.solicitudesContenedorDegradado {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 300px;
}
.solicitudesContenedorTituloPaginacion {
    display: flex;
    justify-content: space-between;
}
.solicitudContenedorPaginacion {
    margin-top: 5px;
}
.solicitudesContenedorTexto {
    padding-left: 0px;
    padding-top: 30px;
}
.solicitudesContenedorTitulo {
    max-width: 600px;
    margin-top: 30px;
}
.solicitudesSubtitulo {
    margin-top: 20px;
    max-width: 600px;
    color: white;
}
.solicitudesContenedorLista {
    height: 600px;
}
.solicitudesContenedorListaOImagen {
    background-color: white;
    margin-bottom: 50px;
}
.solicitudesContenedorImagenFondo {
    text-align: center;
    padding-top: 140px;
    padding-bottom: 140px;
}
.solicitudesImagenFondo{
    width: 400px;
    opacity: 0.5;
}
.solicitudesEstiloTitulo {
    border-bottom: 1px solid #8a8a8a;
    width: 60%;
    font-size: 36px;
    padding: 0 0 15px;
    font-weight: 700;
    margin: 10px 0;
    line-height: 1.1;
    color: white;
}
.solicitudesEstiloSubtitulo {
    color: #ffffff;
    font-weight: 400;
    font-size: 25px;
    margin: 0 0 20px;
}
@media screen and (max-width: 1125px) {
    .solicitudesContenedorImagenFondo {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .solicitudesImagenFondo{
        width: 300px;
    }
}
@media screen and (max-width: 900px) {
    .solicitudesContenedorTituloPaginacion {
        display: grid;
        justify-content: left;
    }
    .solicitudContenedorPaginacion {
        margin-left: -10px;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 830px) {
    .solicitudesContenedorLista {
        height: 700px;
    }
    .solicitudesContenedorImagenFondo {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .solicitudesImagenFondo{
        width: 200px;
    }
}
@media screen and (max-width: 660px) {
    .solicitudesContenedorTexto {
        padding-top: 20px;
        margin-left: 5px;
    }
    .solicitudesEstiloTitulo {
        font-size: 26px;
    }
    .solicitudesEstiloSubtitulo {
        font-size: 20px;
    }
    .solicitudesContenedorDegradado {
        height: 250px;
    }
}

@media screen and (max-width: 520px) {
    .solicitudesContenedorLista {
        height: 800px;
    }
}

@media screen and (max-width: 400px) {
    .solicitudesContenedorLista {
        height: 900px;
    }
}
@media screen and (max-width: 360px) {
    .solicitudesImagenFondo{
      width: 180px;
    }
}

@media screen and (max-width: 340px) {
    .solicitudesImagenFondo{
      width: 150px;
    }
    .solicitudesContenedorLista {
        height: 950px;
    }
}
