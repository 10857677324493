.homeTextoH2Titulo {
    font-size: 36px;
    padding: 0 0 15px;
    font-weight: 700;
    margin: 10px 0;
    line-height: 1.1;
    color: white;
}
.homeTextoP {
    font-size: 17px;
    padding: 0 0 15px;
    font-weight: 400;
    margin: 10px 0;
    line-height: 1.1;
    color:whitesmoke;
}
.homeEstiloLogoTecnigo {
    width: 250px;
}
.homeContenedorEstiloLogoTecnigo{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}
.homeContenedorGeneralServicios {
    margin-right: 100px;
    margin-left: 100px;
}
.homeContenedorServicios {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    justify-items: center;
    gap: 0px;
}
.homeContenedorGeneralUnServicio{
    width: 180px;
    margin-top: 40px;
    cursor: pointer;
}
.homeContenedorUnServicio {
    text-align: center;
}
.homeCirculo {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background:#05ce91;
    padding: 15px;
    transition: background-color .3s;
}
.homeCirculo:hover {
    background-color: #00A775;
}
@media screen and (max-width: 1125px) {
    .homeContenedorGeneralServicios {
        margin-right: 30px;
        margin-left: 30px;
    }
}
@media screen and (max-width: 1050px) {
    .homeContenedorGeneralServicios {
        margin-right: 0px;
        margin-left: 0px;
    }
}
@media screen and (max-width: 990px) {
    .homeContenedorGeneralUnServicio{
        width: 160px;
    }
    .homeEstiloLogoTecnigo {
        width: 200px;
    }
}
@media screen and (max-width: 900px) {
    .homeContenedorServicios {
        grid-template-columns: repeat(4,1fr);
    }
    .homeContenedorGeneralServicios {
        margin-right: 50px;
        margin-left: 50px;
    }
    .homeTextoH2Titulo {
        font-size: 31px;
        font-weight: 600;
    }
}
@media screen and (max-width: 825px) {
    .homeContenedorGeneralServicios {
        margin-right: 30px;
        margin-left: 30px;
    }
}
@media screen and (max-width: 770px) {
    .homeContenedorGeneralServicios {
        margin-right: 10px;
        margin-left: 10px;
    }
}
@media screen and (max-width: 730px) {
    .homeContenedorServicios {
        grid-template-columns: repeat(3,1fr);
    }
    .homeContenedorGeneralServicios {
        margin-right: 50px;
        margin-left: 50px;
    }
    .homeEstiloLogoTecnigo {
        width: 160px;
    }
    .homeContenedorEstiloLogoTecnigo{
        margin-top: 10px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 650px) {
    .homeContenedorGeneralServicios {
        margin-right: 20px;
        margin-left: 20px;
    }
    .homeContenedorEstiloLogoTecnigo{
        margin-top: -5px;
    }
    .homeTextoH2Titulo {
        font-size: 26px;
    }
}
@media screen and (max-width: 578px) {
    .homeContenedorGeneralServicios {
        margin-right: 10px;
        margin-left: 10px;
    }
    .homeTextoH2Titulo {
        font-size: 22px;
        letter-spacing: 0.05em;
    }
}
@media screen and (max-width: 550px) {
    .homeContenedorServicios {
        grid-template-columns: repeat(2,1fr);
    }
    .homeContenedorGeneralServicios {
        margin-right: 60px;
        margin-left: 60px;
    }
}
@media screen and (max-width: 495px) {
    .homeContenedorGeneralServicios {
        margin-right: 40px;
        margin-left: 40px;
    }
}
@media screen and (max-width: 460px) {
    .homeContenedorGeneralServicios {
        margin-right: 20px;
        margin-left: 20px;
    }
}
@media screen and (max-width: 420px) {
    .homeContenedorGeneralServicios {
        margin-right: 5px;
        margin-left: 5px;
    }
}
@media screen and (max-width: 390px) {
    .homeContenedorGeneralServicios {
        margin-right: 0px;
        margin-left: 0px;
    }
}
@media screen and (max-width: 380px) {
    .homeContenedorGeneralUnServicio{
      width: 150px;
    }
}
@media screen and (max-width: 360px) {
    .homeContenedorGeneralUnServicio{
      width: 140px;
    }
}
@media screen and (max-width: 340px) {
    .homeContenedorGeneralUnServicio{
      width: 130px;
    }
}