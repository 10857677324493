
.headerTextoResponsiveButton {
    width: 0px;
    overflow: hidden;
}
.authHeaderEstiloTexto {
    color: rgba(255, 255, 255, 0.924);
    text-decoration: none;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 15px 2px;
}

.authHeaderEstiloNombreResponsive {
    width: 0px;
    overflow: hidden;
}
.authHeaderEstiloNav {
    color: rgba(255, 255, 255, 0.924);
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 15px 2px;
    position: relative;
}
.authHeaderEstiloNav:hover {
    color: #fff;
}
.authHeaderEstiloNav:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
.authHeaderEstiloNav:hover:after { 
    width: 100%; 
    left: 0;
}
.authHeaderEstiloUl{
    margin-top: 0;
    order: 0;
    display: flex;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
}

.authHeaderLi {
    list-style-type: none;
    margin-right: 30px;
}
.authHeaderEstiloNavActive {
    color: rgba(255, 255, 255, 0.924);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: inline-block;
    padding: 15px 2px;
    position: relative;
    text-decoration: none;
    border-bottom: 2px solid #fff;
    display: inline-block;
    padding-bottom: 13px;
}
.authHeaderEstiloNavActive:hover {
    border-bottom: 0px solid #fff;
}
/**
 *  Movil
*/
.headerHomeEstiloNav {
    color: #474849;
    text-decoration: none;
    letter-spacing: 0.05em;
    position: relative;
}
.authHeaderEstiloNavMovil {
    color: #474849;
    text-decoration: none;
    letter-spacing: 0.05em;
    display: inline-block;
    position: relative;
}

.authHeaderNombreEmpresa {
    color: #FAA634;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 1120px) {
    .authHeaderNombreEmpresa {
        width: 450px;
    }
}
@media screen and (max-width: 1050px) {
    .authHeaderNombreEmpresa {
        width: 400px;
    }
}
@media screen and (max-width: 960px) {
    .authHeaderNombreEmpresa {
        width: 500px;
    }
}
@media screen and (max-width: 880px) {
    .authHeaderNombreEmpresa {
        width: 400px;
    }
}
@media screen and (max-width: 750px) {
    .authHeaderNombreEmpresa {
        width: 300px;
    }
}

@media screen and (max-width: 650px) {
    .authHeaderNombreEmpresa {
        width: 0px;
    }
    .headerTextoResponsive {
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
    .headerTextoResponsiveButton {
        width: auto;
        overflow: visible;
    }
    .authHeaderEstiloNombreResponsive {
        color: rgba(255, 255, 255, 0.924);
        text-decoration: none;
        display: inline-block;
        padding: 15px 2px;
        width: auto;
        overflow: visible;
    }
}