.footerGridMainContainer {
    background-color: #343D8E;
}
.footerGridSecondContainer {
    margin-top: 10px;
}
.footerGridSecondBottomContainer {
    padding-bottom: 20px;
}
.footerDivImage {
    padding-left: 80px;
    padding-top:16px;
    padding-right: 10px;
}
.footerLogoImageSize {
    width: 150px;
}
.footerLogoGooglePlayImageSize {
    width: 140px;
}
.footerDivContenedorIcons {
    text-align: center;
    margin-top: 10px;
}
.footerColorBlanco {
    color: white;
}
.footerColorBlancoLink {
    color: silver;
}
.footerDivContainerImage{
    text-align: center;
    margin-top: 10px;
}