.perfilDegradado {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 300px;
}
.perfilContenedorEncabezado {
    display: flex;
}
.perfilCirculo {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: white;
    padding: 15px;
}
.perfilContenedorTitulo{
    margin-top: 66px;
    margin-left: 30px;
    width: 300px;
}
.perfilEstiloTitulo {
    border-bottom: 1px solid #8a8a8a;
    width: 100%;
    font-size: 36px;
    padding: 0 0 15px;
    font-weight: 700;
    margin: 10px 0;
    line-height: 1.1;
    color: white;
}
.perfilEstiloSubtitulo {
    color: #ffffff;
    font-weight: 400;
    font-size: 25px;
    margin-top: 20px;
}
/**
 * Responsive
*/
@media screen and (max-width: 660px) {
    .perfilContenedorTitulo{
        margin-top: 73px;
        margin-left: 25px;
        width: 200px;
    }  
    .perfilEstiloTitulo {
        font-size: 26px;
    }
    .perfilEstiloSubtitulo {
        font-size: 20px;
    }
    .perfilDegradado {
        height: 250px;
    }
}
@media screen and (max-width: 500px) {
    .perfilCirculo {
        width: 85px;
        height: 85px;
    }
}