.solicitudDetContenedorDegradadoDetalle {
    background: linear-gradient(180deg, #343D8E,#187581,#00A775);
    height: 300px;
}
.solicitudDetContenedorTexto {
    padding-left: 0px;
    padding-top: 30px;
}
.solicitudDetContenedorTitulo {
    max-width: 600px;
    margin-top: 30px;
}
.solicitudDetSubtitulo {
    margin-top: 20px;
    max-width: 600px;
    color: white;
}
.solicitudDetContenedorCardTicket {
    margin-top: 30px;
}
.solicitudDetContenedorGeneralSolicitudDetalle {
    margin-top: 30px;
}
.solicitudDetContenedorTituloDetalle{
    margin-top: 30px;
}
.solicitudDetBotonInforme {
    box-sizing: border-box;
    text-decoration: none;
    letter-spacing: .5px;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.2rem 1rem;
    line-height: 187.5%;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color:#3c30920e;
    align-self: center !important;
    text-transform: uppercase !important;
    font-size: 0.875rem;
    cursor: pointer;
}
.solicitudDetBotonInforme:hover {
    background-color:#3c30921a;
    color: #3340b8;
}
.solicitudDetEstiloTitulo {
    border-bottom: 1px solid #8a8a8a;
    width: 60%;
    font-size: 36px;
    padding: 0 0 15px;
    font-weight: 700;
    margin: 10px 0;
    line-height: 1.1;
    color: white;
}
.solicitudDetEstiloSubtitulo {
    color: #ffffff;
    font-weight: 400;
    font-size: 25px;
    margin: 0 0 20px;
}
@media screen and (max-width: 959px) {
    .solicitudDetContenedorTituloDetalle{
        margin-top: 250px;
    }
}
@media screen and (max-width: 650px) {
    .solicitudDetContenedorTituloDetalle{
        margin-top: 300px;
    }
}
@media screen and (max-width: 660px) {
    .solicitudDetContenedorTexto {
        padding-top: 20px;
        margin-left: 5px;
    }
    .solicitudDetEstiloTitulo {
        font-size: 26px;
    }
    .solicitudDetEstiloSubtitulo {
        font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .solicitudDetContenedorTituloDetalle{
        margin-top: 480px;
    }
}
@media screen and (max-width: 376px) {
    .solicitudDetContenedorTituloDetalle{
        margin-top: 530px;
    }
}
