.loginContenedorColor {
    background: linear-gradient(180deg, #3B3092,#00A775);
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    width: 100% ;
    min-height:100vh;
}
.loginContenedorRegistro {
    margin-right: 120px;
    margin-left: 120px;
    margin-bottom: 100px;
}
/**
 * Media Screen
*/
@media screen and (max-width: 1125px) {
    .loginContenedorRegistro {
        margin-right: 90px;
        margin-left: 90px;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 1000px) {
    .loginContenedorRegistro {
        margin-right: 80px;
        margin-left: 80px;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 959px) {
    .loginContenedorColor {
        height: 0px;
        min-height:0vh;
    }
}
@media screen and (max-width: 660px) {
    .loginContenedorRegistro {
        margin-right: 30px;
        margin-left: 30px;
        margin-bottom: 100px;
    }
}