.botonCirculoAtrasVerdeLinkBotonPersonalizado {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    padding-top: 8px;
    padding-left: 5px;
    width: 40px;
    height: 40px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color:#00A775;
    border-color: #00A775;
    align-self: center !important;
    text-transform: uppercase !important;
    cursor: pointer;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.botonCirculoAtrasVerdeLinkBotonPersonalizado:hover {
    color: #00A775;
    background-color: #fff;
    border-color: #00A775;
}